import * as React from "react";
import { List, Datagrid, TextField, BooleanField } from 'react-admin';
import { Button, TopToolbar, CreateButton} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';

const ListActions = (props) => {
    return false;
};

export const NodeList = props => (
    <List {...props} title="Ubris | grex"
                     actions={<ListActions />}
                     exporter="false"
    >
        <Datagrid>
            <TextField source="host_uuid" />
            <TextField source="hostname" />
            <TextField source="ip_address" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);
