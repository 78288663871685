import * as React from "react";
import { List, Datagrid, TextField, TextInput} from 'react-admin';

const ListActions = (props) => {
    return false;
};

export const ResultList = props => (
    console.log(props),
    <List {...props} title="Ubris | results"
                     actions={<ListActions />}
                     exporter="false"
                     filters={resultsFilters}
    >
        <Datagrid>
            <TextField source="data" />
        </Datagrid>
    </List>
);

const resultsFilters = [
    <TextInput label="Search" source="id" alwaysOff />,
];