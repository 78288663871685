import * as React from "react";
import { List, Create, CreateButton, SimpleForm, Datagrid, TextInput, PasswordInput, TopToolbar, EmailField, TextField, BooleanField} from 'react-admin';

export const UserList = props => (
    <List {...props} actions={<ListActions/>}>
        <Datagrid>
            <TextField label="_id" source="id" />
            <EmailField label="Username (Email)" source="email" />
            <TextField label="First name" source="first_name" />
            <TextField label="Last name" source="last_name" />
            <BooleanField label="Administrator" source="admin" />
        </Datagrid>
    </List>
);

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" />
            <PasswordInput source="password" />
        </SimpleForm>
    </Create>
);
