import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: 'url(' + process.env.PUBLIC_URL + '/sfondumgmt.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
    },
    MuiButtonBase: { color: 'white' },
    icon: { display: 'none' },
    });


const CustomLoginForm = withStyles({
    button: { background: '#FF0000' },
})(LoginForm);


const CustomLoginPage = props => (
    <Login loginForm={<CustomLoginForm />}
        {...props}
    />

);

export default withStyles(styles)(CustomLoginPage);