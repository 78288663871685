import * as React from "react";
import {
    List,
    Create,
    CreateButton,
    SimpleForm,
    Datagrid,
    RadioButtonGroupInput,
    TextInput,
    TopToolbar,
    TextField} from 'react-admin';



export const SearchList = props => (
    <List {...props} actions={<ListActions/>}>
        <Datagrid rowClick={(id: Identifier) => `/results?filter={"id":"${id}"}`}>
            <TextField label="Query" source="query" />
            <TextField label="Search Type" source="search_type" />
        </Datagrid>
    </List>
);

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);


export const SearchCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <RadioButtonGroupInput source="search_type" choices={[
                { id: 'sql', name: 'SQL' },
                { id: 'fulltext', name: 'Fulltext' },
            ]} />
            <TextInput source="query" initialValue=""/>
            <TextInput source="callback" initialValue="" />
            <TextInput source="pubkey" initialValue="" />
        </SimpleForm>
    </Create>
);