import * as React from "react";

import { fetchUtils, Admin, Resource } from 'react-admin';

import jsonServerProvider from 'ra-data-json-server';

import SearchIcon from '@material-ui/icons/Search';
import ResultsIcon from '@material-ui/icons/Equalizer';
import UserIcon from '@material-ui/icons/People';
import GrexIcon from '@material-ui/icons/ScatterPlot';

import authProvider from './authProvider';
import LoginPage from './LoginPage'

import { SearchList, SearchCreate } from './searches';
import { UserList, UserCreate } from './users';
import { NodeList } from './nodes';
import { ResultList } from './results';



const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = JSON.parse(localStorage.getItem('auth')).access_token;
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
};

// const dataProvider = jsonServerProvider('http://api.ubris.local/v1', httpClient);
const dataProvider = jsonServerProvider(process.env.REACT_APP_API_SERVER + '/v1', httpClient);

const App = () => (
    <Admin authProvider={authProvider}
           dataProvider={dataProvider}
           loginPage={LoginPage}>

        <Resource name="search"
                  options={{ label: 'Search' }}
                  list={SearchList}
                  create={SearchCreate}
                  icon={SearchIcon}/>

        <Resource name="results"
                  options={{ label: 'Results' }}
                  list={ResultList}
                  icon={ResultsIcon}/>

        <Resource name="users"
                  options={{ label: 'Users' }}
                  list={UserList}
                  create={UserCreate}
                  icon={UserIcon}/>

        <Resource name="node"
                  options={{ label: 'Grex' }}
                  list={NodeList}
                  icon={GrexIcon}/>

    </Admin>
);

export default App;